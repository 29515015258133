<template>
  <div id="container">
    <section id="contact">
      <div class="wrap">
        <div class="cts_box">
          <h2 class="main_ttl"><span>お問い合わせ</span></h2>
          <p class="lead_txt">
            複眼経済塾ではお電話・メールでのお問い合わせも承っております。当塾についてご不明な点がございましたら以下の電話番号またはメールフォームよりお問い合わせください。
          </p>
          <p class="lead_txt alnC">
            当塾の利用法などは「
            <router-link to="/faq" class="blue bold">よくあるご質問</router-link>
            」にもまとめています。<br>
            合わせてご利用ください。
          </p>
        </div>
        <div class="cts_box" id="form-top">
          <h3 class="min_ttl"><span>メールでのお問い合わせはこちら</span></h3>
          <p class="lead_txt">
            メールでのお問い合わせを希望される塾生の方は、以下のフォームにご入力の上「送信する」ボタンを押してください。<br>
            <span class="blue bold">*のついている箇所は必ず入力して下さい。</span>
          </p>
          <form action="">
            <div class="msg_box error" v-if="errors">
              <h3>ご入力内容にエラー項目があります</h3>
              <p>エラー個所は赤く表示され、エラー理由がすぐ下に赤字で示されます。<br>お手数ですが、エラー項目を修正してからボタンを押してください。
              </p>
            </div>

            <div class="input_set name">
              <div class="label">おなまえ<span>*</span></div>
              <div class="input_fields flex">
                <div class="field">
                  <div class="label">姓</div>
                  <input type="text" placeholder="例：山田" v-bind:class="{error: validation_errors.last_name}"
                         v-model="last_name">
                  <ValidationErrors :errors="validation_errors.last_name"
                                    v-if="validation_errors.last_name"></ValidationErrors>
                </div>
                <div class="field">
                  <div class="label">名</div>
                  <input type="text" placeholder="例：太郎" v-bind:class="{error: validation_errors.first_name}"
                         v-model="first_name">
                  <ValidationErrors :errors="validation_errors.first_name"
                                    v-if="validation_errors.first_name"></ValidationErrors>
                </div>
              </div>
            </div>
            <div class="input_set email">
              <div class="label">連絡先メールアドレス<span>*</span></div>
              <p>（半角英数でご入力下さい。）</p>
              <input type="email" value="" placeholder="例：yamada@hukugankeizai.com"
                     v-bind:class="{error: validation_errors.email}" v-model="email">
              <ValidationErrors :errors="validation_errors.email"
                                v-if="validation_errors.email"></ValidationErrors>
            </div>
            <div class="input_set phone">
              <div class="label">連絡先電話番号</div>
              <p>（半角数字で日中連絡の取れるお電話番号をご入力下さい。）</p>
              <input type="tel" placeholder="例：09012345678" v-bind:class="{error: validation_errors.phone_number}"
                     v-model="phone_number">
              <ValidationErrors :errors="validation_errors.phone_number"
                                v-if="validation_errors.phone_number"></ValidationErrors>
            </div>
            <div class="input_set">
              <div class="label">お問い合わせの内容<span>*</span></div>
              <textarea name="" placeholder="お問い合わせの内容をご記入ください"
                        v-bind:class="{error: validation_errors.description}"
                        v-model="description"></textarea>
              <ValidationErrors :errors="validation_errors.description"
                                v-if="validation_errors.description"></ValidationErrors>
            </div>
            <div class="button_wrap">
              <p>必要な項目にすべて記入できたら<br class="sp">こちらのボタンを押してください。</p>
              <p class="red" v-if="isNewYearVacations()">
                複眼経済塾は下記の期間、年末年始休業とさせていただきます。<br>
                2023年12月29日（金）～2024年1月4日（木）<br>
                この間にいただいたお問い合わせについては5日以降順次お返事いたします。<br>
                何卒よろしくお願いいたします。
              </p>

              <input type="button" class="submit light arrow" value="送信する" v-on:click="sendClicked()"
                     v-bind:disabled="disabled">
            </div>
          </form>
        </div>
        <div class="cts_box tel_info">
          <p class="lead_txt">
            お問い合わせはお電話でも承っております<br class="sp">（受付時間 平日 10：00〜17：00）
          </p>
          <div class="contact_tel">
            <a href="tel:0362808451" class="flex">
              <span class="icon_tel">03-6280-8451</span>
            </a>
          </div>
        </div>
      </div>
    </section>
    <div id="b_listBox">
      <ul id="b_list">
        <li>
          <router-link to="/">トップページ</router-link>
        </li>
        <li>お問い合わせ</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from '@/components/ValidationErrors.vue'

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors
  },
  data() {
    return {
      errors: false,
      validation_errors: {},
      first_name: this.$route.meta.current_user ? this.$route.meta.current_user.first_name : null,
      last_name: this.$route.meta.current_user ? this.$route.meta.current_user.last_name : null,
      number: this.$route.meta.current_user ? this.$route.meta.current_user.number : null,
      email: this.$route.meta.current_user ? this.$route.meta.current_user.email : null,
      phone_number: this.$route.meta.current_user ? this.$route.meta.current_user.phone_number : null,
      description: null,
      disabled: false
    }
  },
  methods: {
    sendClicked() {
      if (this.disabled) return

      const contact = {
        first_name: this.first_name,
        last_name: this.last_name,
        number: this.number,
        email: this.email,
        phone_number: this.phone_number,
        description: this.description
      }

      this.disabled = true
      this.axios
          .post(`${this.env.api_base_url}contacts.json`, {
            contact: contact
          })
          .then(() => {
            this.$store.state.contact = contact
            this.$router.push({name: 'ContactsFinish'})
          })
          .catch(error => {
            this.errors = true
            if (error.response.data) {
              if (error.response.data.errors) {
                this.errors = true
                this.scrollTo('#form-top')
              }
              if (error.response.data.validation_errors) {
                this.validation_errors = error.response.data.validation_errors
              }
            }
          })
          .finally(() => {
            this.disabled = false
          })
    },
    // 年末年始の期間か
    isNewYearVacations() {
      const now = new Date()
      return now >= new Date('2023-12-22 00:00:00') && now <= new Date('2024-01-05 23:59:59')
    }
  }
}
</script>
